import React from 'react'
import Form from 'src/components/UI/Forms/CartaoInss'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import { Section } from './style'

const Header = ({ sendDatalayerEvent }: DataLayerProps) => {
  return (
    <Section id='form'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 pt-md-0'>
            <h1>
              Cartão Benefício INSS: até 70% do seu limite de crédito na sua mão!
            </h1>
            <p className='header-subtitle'>
              Garanta o seu novo Cartão Benefício INSS e faça compras no crédito com as menores taxas do mercado ou
              saque até 70% do seu limite e pague em até 84x!
            </p>
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <Form sendDatalayerEvent={sendDatalayerEvent} />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Header
