import React from 'react'
import usePageQuery from '../../pageQuery'
import Img from 'gatsby-image'

import { Section } from './style'

const OQueEOCartao = () => {
  const data = usePageQuery()

  return (
    <Section className='position-relative' id='cartao'>
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-xl-3 col-lg-5'>
            <Img fluid={data.bannerOQueEOCartao.fluid} alt='Ilustração de uma mão segurando o cartão.' />
          </div>
          <div className='col-12 col-md-6 col-xl-5 offset-lg-1 offset-xl-2 pt-md-0'>
            <h2>
              O que é o Cartão Benefício INSS?
            </h2>
            <p>Ele é um cartão de crédito comum, porém com taxas muito menores e benefícios exclusivos para aposentados
              e pensionistas do INSS. O Cartão Benefício INSS não é um Empréstimo Consignado.
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default OQueEOCartao
