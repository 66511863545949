import styled from 'styled-components'
import { breakpoints, device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: #F5F6FA;
  min-height: 686px;
  padding-bottom: 48px;
  padding-top: 48px;

  @media ${device.tablet} {
    min-height: 343px;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding-bottom: 96px !important;
    padding-top: 96px !important;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 594px;
    padding-bottom: 96px !important;
    padding-top: 96px !important;
  }

  @media (min-width: ${breakpoints.xxxl}) {
    padding-bottom: 96px !important;
    padding-top: 96px !important;
  }

  h2 {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #161616;
    margin-top: 16px;

    @media ${device.desktopLG} {
      font-size: 40px;
      line-height: 50px;
    }
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #6A6C72;
    margin-top: 16px;
    margin-bottom: 21px;

    @media ${device.desktopLG} {
      font-size: 18px;
      line-height: 22px;
    }
  }

  @media ${device.tablet} {
    .container-como-pedir-meu-cartao {
      flex-direction: row-reverse;
    }
  }

  .btn {
    height: 40px;
    @media ${device.desktopLG} {
      min-width: 418px;
    }
  }

  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      width: 400px;
    }
  }
`
