import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerComoPedirMeuCartao: imageSharp(fluid: {originalName: { regex: "/banner-como-pedir-meu-cartao/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerOQueEOCartao: imageSharp(fluid: {originalName: { regex: "/banner-o-que-e-o-cartao/" }}) {
        fluid(maxWidth: 250, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerComoFuncionaOCartao: imageSharp(fluid: {originalName: { regex: "/banner-como-funciona-o-cartao/" }}) {
        fluid(maxWidth: 312, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
