import { breakpoints } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  padding-bottom: 48px;
  padding-top: 48px;

  @media (min-width: ${breakpoints.lg}) {
    padding-bottom: 96px !important;
    padding-top: 96px !important;
  }

  @media (min-width: ${breakpoints.xl}) {
    padding-bottom: 96px !important;
    padding-top: 96px !important;
  }

  @media (min-width: ${breakpoints.xxxl}) {
    padding-bottom: 96px !important;
    padding-top: 96px !important;
  }
`

export const Title = styled.h3`
  display: block;
  font-family: 'Sora';
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${grayscale[500]};

  @media (min-width: ${breakpoints.md}) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 40px;
    line-height: 50px;
  }
`
