import React from 'react'
import usePageQuery from '../../pageQuery'
import Img from 'gatsby-image'

import { Section } from './style'
import ScrollTo from 'src/components/ScrollTo'

const ComoPedirMeuCartao = () => {
  const data = usePageQuery()

  return (
    <Section className='position-relative' id='como-pedir'>
      <div className='container'>
        <div className='container-como-pedir-meu-cartao row justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-lg-5'>
            <Img fluid={data.bannerComoPedirMeuCartao.fluid} alt='Ilustração do cartão.' />
          </div>
          <div className='col-12 col-md-6 col-xl-5 pt-md-0'>
            <h2>
              Como pedir meu Cartão de Crédito Consignado INSS?
            </h2>
            <p>
              Para solicitar o seu Cartão Benefício INSS, basta preencher o nosso formulário com seus
              dados (nome, CPF e contato), concluir o cadastro e, caso esteja tudo certo, logo em seguida,
              você receberá uma mensagem informando para aguardar o nosso contato.
            </p>
            <ScrollTo
              to='#form'
              section='dobra_06'
              sectionName='Como pedir meu Cartão de Crédito Consignado INSS?'
              elementName='Garanta o seu Cartão Benefício!'
            >
              <button
                title='Garanta o seu Cartão Benefício'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-md-block mt-md-5'
              >
                Garanta o seu Cartão Benefício!
              </button>
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ComoPedirMeuCartao
