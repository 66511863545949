import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Header from './sections/header/_header'
import SumarySection from './sections/SumarySection/_index'
import OQueEOCartao from './sections/o-que-e-o-cartao/_OQueEOCartao'
import ComoFunciona from './sections/como-funciona/_ComoFunciona'
import QuaisAsVantagens from './sections/quais-sao-as-vantagens/_QuaisSaoAsVantagens'
import ComoPedirMeuCartao from './sections/como-pedir-meu-cartao/_ComoPedirMeuCartao'
import Faq from './sections/Faq/_Faq'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const CartaoInss = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Header sendDatalayerEvent={sendDatalayerEvent} />
        <SumarySection sendDatalayerEvent={sendDatalayerEvent} />
        <OQueEOCartao />
        <ComoFunciona />
        <QuaisAsVantagens />
        <ComoPedirMeuCartao />
        <Faq faq={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}

export default CartaoInss
