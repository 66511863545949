import React from 'react'
import Summary from 'src/components/Summary'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import sumaryData from '../../assets/data/sumaryData.json'

const SumarySection = ({ sendDatalayerEvent }: DataLayerProps) => {
  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Summary
              icon='userdoubt'
              title='O que você procura?'
              links={sumaryData}
              section='dobra_2'
              sendDatalayerEvent={sendDatalayerEvent}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SumarySection
