import React from 'react'

import { SectionWrapper, Title } from './style'

import Faq from 'src/components/Faq/index'

type Faq = {
  faq: { question: string; answer: string }[];
}

const FAQComponent = (props: Faq) => {
  return (
    <SectionWrapper id='faq'>
      <div className='container'>
        <div className='mb-4'>
          <Title>
            Perguntas frequentes sobre o cartão benefício INSS
          </Title>
        </div>
        <Faq
          answerData={props.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
        />
      </div>
    </SectionWrapper>
  )
}

export default FAQComponent
