import React from 'react'
import vantagensSectionContent from '../../assets/data/vantagensSectionContent.json'
import Icon from 'src/components/UI/MarkdownIcon'
import ScrollTo from 'src/components/ScrollTo'

import { ItensProps } from './types'
import { Section } from './style'

const QuaisAsVantagens = () => {
  return (
    <Section className='position-relative' id='quais-as-vantagens'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-5'>
            <h3>Quais são as vantagens do Cartão Benefício INSS?</h3>
            <p>O Cartão Benefício INSS tem bandeira Mastercard, zero anuidade e muito mais vantagens para aposentados
              e pensionistas do INSS! As Parcelas são descontadas em até 5% do seu benefício, sendo restante pago via fatura.
            </p>
          </div>

          {vantagensSectionContent.map((item: ItensProps, index: number) => {
            return (
              <div key={index} className='col-lg-4 col-md-4 mb-5'>
                <div className='text-center'>
                  <Icon color='orange--base' width='60' height='60' icon={item.icon} directory='v2' />
                  <p className='iconText'>{item.text}</p>
                </div>
              </div>
            )
          })}
          <div className='col-12 d-flex justify-content-center'>
            <ScrollTo
              to='#form'
              section='dobra_04'
              sectionName='Quais são as vantagens do Cartão Benefício INSS?'
              elementName='Garanta o seu Cartão Benefício!'
            >
              <button
                title='Garanta o seu Cartão Benefício'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center d-md-block'
              >
                Garanta o seu Cartão Benefício!
              </button>
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default QuaisAsVantagens
